// export const HOST             = 'https://production-deep.envirowasteadmin.com.au/'
//export const HOST               = 'http://192.168.1.65:8000/'
export const HOST               = 'https://staging-deep.envirowasteadmin.com.au/'
// export const HOST               = 'http://172.16.2.196:8000/'

//export const HOST_WITHOUT_SLASH = 'https://staging-deep.envirowasteadmin.com.au'
export const HOST_WITHOUT_SLASH = ''

// export const IMAGE_BASE_URL = ''
    const API_VERSION           = 'api/v1/'
    // const API_VERSION_2         = 'api/v2/'
    const API_VERSION_3         = 'api/v3/'
    const API_VERSION_4         = 'api/v4/'
    const BASE                  = HOST + API_VERSION
    // const BASE_V2               = HOST + API_VERSION_2
    const BASE_V3               = HOST + API_VERSION_3
    const BASE_V4               = HOST + API_VERSION_4
/////////////////////////////// AUTHENTICATION //////////////////////////////////////////////////////////

export const AUTHENTICATION         = HOST + 'api/token/'
export const PROFILE                = BASE + 'team/profile/'
export const ACCOUNT_PROFILE        = BASE + 'accounts/profile/view/'
export const ACCOUNT_PERMISSIONS    = BASE + 'permissions/all/?platform=web'

/////////////////////////////// PUSH NOTIFICATION //////////////////////////////////////////////////////////

export const PUSH_NOTIFICATION          = BASE + 'accounts/push-notification/key/'
export const PUSH_NOTIFICATION_DELETE   = BASE + 'accounts/web/push-notification/delete/'   // append fcm

/////////////////////////////////////// HOME //////////////////////////////////////////////////////////

export const HOME_GET_ALL           = BASE + 'home/view/'
export const HOME_WEATHER           = BASE + 'home/view/weather_report/'

////////////////////////////////////// TEAM /////////////////////////////////////////////////////////////

export const TEAM_LIST              = BASE + 'team/employee/all/'
export const TEAM_ADD               = BASE + 'team/create/'
export const TEAM_EDIT              = BASE + 'team/employee/edit/'
export const TEAM_DELETE            = BASE + 'team/employee/delete/'
export const TEAM_DESIGNATIONS      = BASE + 'team/designations/'
export const TEAM_FOLDER_WITH_FILES = BASE + 'team/folder/'
export const TEAM_FILE_UPLOAD       = BASE + 'team/files/'
export const TEAM_FILE_DELETE       = BASE + 'team/employee/file/delete/'
export const TEAM_ADD_FOLDER        = BASE + 'team/update/folder/create/'
export const TEAM_EDIT_FOLDER       = BASE + 'team/update/folder/'
export const TEAM_SEARCH            = BASE + 'team/searchEmployee/'
export const TEAM_CURR_LIST         = BASE + 'team/status/current/'
export const TEAM_TERM_LIST         = BASE + 'team/status/terminated/'

export const TEAM_SINGLE_EMPLOYEE   = BASE + 'team/employee/'
//home page folder
export const TEAM_ALL_FOLDERS_LIST  = BASE + 'team/folder/create/'
//get all folders
export const TEAM_FOLDER_DETAILS    = BASE + 'team/folder/list/'
export const TEAM_FOLDER_FILES      = BASE + 'team/files/list/'
// employee serch in folder
export const TEAM_FOLDER_SEARCH     = BASE + 'team/folder/search/'
//certificate for each employee 
export const TEAM_CERTIFICATE       = BASE + 'team/folder/'
//add file to the employee folder
export const TEAM_FOLDER_ADD_FILE   = BASE + 'team/files/'

export const TEAM_DANGEROUS         = BASE + 'team/safety/info/'
export const TEAM_FOLDER_INFO       = BASE + 'team/warning/info/'

export const TEAM_FOLDER_CREATE     = BASE + 'drive/create/team/folder/'
export const TEAM_GET_FOLDERS       = BASE + 'drive/team/master/folder/'
export const TEAM_GET_INNER_FOLDERS = BASE + 'drive/team/folder/'
export const TEAM_INNER_FOLDER_CREATE = BASE + 'drive/create/team/individual/folder/'
export const TEAM_INNER_FILE_CREATE = BASE + 'drive/team/add/files/'

export const LEAVEAPPLY_CRUD        = BASE + 'team/leave/crud/'
export const TIMESHEET_CRUD         = BASE + 'team/times-sheet/crud/'
export const LEAVESTATUS_UPDATE     = BASE + 'team/leave/application/' // status :- rejected,approved

export const TEAM_REFRESH_LOGIN     = BASE + 'team/refresh/login/'

export const TEAM_VEHICLE_SCHEDULE_EXIST = BASE + 'vehicles/deletion/check/'
export const TEAM_EMPID_EXIST       = BASE + 'team/user_id/check/'
export const TEAM_USRNAME_EXIST     = BASE + 'team/username/check/'

export const TEAM_SALESPERSON_GET   = BASE + 'team/salespersons/'

//////////////////////////////////////////// CLIENT ////////////////////////////////////////////////////////////

export const CLIENT_GET                     = BASE + 'clients/all/'
export const CLIENT_ADD                     = BASE + 'clients/create/'
export const CLIENT_EDIT                    = BASE + 'clients/edit/'
export const CLIENT_DELETE                  = BASE + 'clients/delete/'
export const CLIENT_SEARCH                  = BASE + 'clients/searchClient/all/' 
export const CLIENT_FOLDERS                 = BASE + 'clients/files/' //append client id
export const CLIENT_FILE_DELETE             = BASE + 'clients/file/delete/'
export const CLIENT_SINGLE_FETCH            = BASE + 'clients/single/'

export const SITE_FILESANDFOLDERS_GET       = BASE + 'drive/site/folder/'
export const SITE_FILES_CREATE              = BASE + 'drive/site/add/files/'
export const SITE_FOLDER_CREATE             = BASE + 'drive/create/site/individual/folder/'
export const SITE_FILESANDFOLDERS_SEARCH    = BASE + 'drive/site/search/'
export const SITE_PRIVATE_FOLDER_CREATE     = BASE + 'drive/create/site/private/folder/'

export const SITE_ATTACH_CRUD               = BASE + 'clients/detail/crud/'

export const SITE_TYPE_OF_WASTE_CRUD            = BASE + ''
export const SITE_TYPE_OF_WASTE_LIST            = BASE + 'clients/available-waste-types/'
export const SITE_TYPE_OF_WASTE_LIST_UPDATED    = BASE + 'clients/childs/available/'
export const SITE_TOTAL_NO                      = BASE + 'clients/no-of-clients/'
export const SCOPEOFWORK_CRUD_TYPE_OF_WASTE     = BASE + 'sales/dropdown/'
export const SCOPOFWORK_CRUD_ADDITIONAL_INFO    = BASE + 'sales/additional_information/'

export const DELETED_CLIENT                 = BASE + 'clients/deleted-client/list/'
export const DELETED_CLIENT_SEARCH          = BASE + 'clients/deleted-client/search/'
export const SITE_INDUSTRY_LIST             = BASE + 'accounts/industry-type/list/'
export const SITE_INDUSTRY_ADD              = BASE + 'accounts/industry-type/create/'
export const SITE_INDUSTRY_EDIT             = BASE + 'accounts/industry-type/edit/'

//////////////////////////////////////////// VEHICLES ///////////////////////////////////////////////////////////////

export const VEHICLE_FILESFOLDERS_GET           = BASE + 'drive/view/vehicle/folder/'
export const VEHICLE_FOLDER_CREATE              = BASE + 'drive/create/vehicle/folder/'
export const VEHICLE_GET                        = BASE + 'vehicles/' // append tab + vehicle category + all + page no
export const VEHICLE_ALL_GET                    = BASE + 'vehicles/VTYPE/list/' //REPLACE VTYPE with TabType/Vehicle
export const VEHICLE_ADD                        = BASE + 'vehicles/create/'
export const VEHICLE_EDIT_MULTIPLE              = BASE + 'vehicles/update/images/list/'
export const VEHICLE_SEARCH                     = BASE + 'vehicles/VTYPE/search/'
export const VEHICLE_FOLDERS                    = BASE + 'vehicles/files/' // append vehicle id
export const VEHICLE_FILE_UPLOAD                = BASE + 'vehicles/files/upload/' // append vehicle id
export const VEHICLE_FILE_DELETE                = BASE + 'vehicles/file/delete/'
export const VEHICLE_FOLDERS_ADD                = BASE + 'vehicles/update/folder/create/'
export const VEHICLE_FOLDERS_EDIT               = BASE + 'vehicles/update/folder/'
export const VEHICLE_ARCHIVE                    = BASE + 'vehicles/archive/'
export const VEHICLE_UNARCHIVE                    = BASE + 'vehicles/unarchive/'
export const VEHICLE_MNTCE_FILESFOLDER          = BASE + 'drive/view/maintanance/folder/'
export const VEHICLE_MNTCE_FILESFOLDER_CREATE   = BASE + 'drive/create/maintanance/folder/'

//////////////////////////////////////////////////// tab-type incl. vehicles /////////////////////////////////////////////////

//REPLACE VTYPE with TabType/Vehicle

export const VEHICLE_MAINTANACE_GET = BASE + 'vehicles/VTYPE/maintenance/reports/'    // append page no
export const VEHICLE_MAINTANACE_SEARCH = BASE + 'vehicles/VTYPE/maintenance/reports/search/'   // append page no
export const VEHICLE_MAINTANACE_EDIT = BASE + 'vehicles/VTYPE/maintenance/report/edit/'   //append maintanance id
export const VEHICLE_MAINTANACE_DELETE = BASE + 'vehicles/VTYPE/maintenance/report/delete/' //append maintanance id
export const VEHICLE_MAINTANACE_ADD = BASE + 'vehicles/VTYPE/maintenance/report/create/'
export const VEHICLE_FUEL_GET = BASE + 'vehicles/VTYPE/getFuelExpenses/' // append page no
export const VEHICLE_FUEL_EDIT = BASE + 'vehicles/VTYPE/editFuelExpense/' //append fuel id
export const VEHICLE_FUEL_DELETE = BASE + 'vehicles/VTYPE/deleteFuelExpense/'  //append fuel id
export const VEHICLE_FUEL_ADD = BASE + 'vehicles/addFuelExpense/'
export const VEHICLE_FUEL_SEARCH = BASE + 'vehicles/VTYPE/fuelExpense/search/vehicle/'
export const VEHICLE_PREINSPECTION = BASE + 'vehicles/VTYPE/pre-inspection/'
export const VEHICLE_SINGLE_PREINSPECTION = BASE + 'vehicles/VTYPE/pre-inspection/get/' 
export const VEHICLE_PREINSPECTION_SEARCH = BASE + 'vehicles/VTYPE/pre-inspection/filter/vehicle/'
export const VEHICLE_PREINSPECT_FILTER = BASE + 'vehicles/VTYPE/pre-inspection/filter/'
export const VEHICLE_FUEL_FILTER  =   BASE + 'vehicles/VTYPE/fuelExpense/search/'
export const VEHICLE_MAINTANACE_FILTER = BASE + 'vehicles/VTYPE/maintenance/reports/search/'

export const VEHICLE_MASTER_PRINT_ALL_CSV = BASE + 'vehicles/VTYPE/export/'
export const VEHICLE_FUEL_PRINT_ALL_CSV       = BASE + 'vehicles/VTYPE/fuelexpense/export/'
export const VEHICLE_MAINTANACE_PRINT_CSV = BASE + 'vehicles/VTYPE/maintenance/report/export/'
export const VEHICLE_PREINSP_PRINT_CSV = BASE + 'vehicles/VTYPE/pre-inspection/export/'

export const VEHICLE_NEW_MAINT_PRINT_CSV = BASE + 'vehicles/VTYPE/maintenance/report/export/'

/////////////////////////////////////////// SALES AND JOBS ////////////////////////////////////////////////////////////////

export const SALES_ALL_SALE_PERFORMANCE = BASE + 'sales/performance/' //append year & month
export const SALES_COMMON_BASE = BASE + 'sales/jobs/'
export const SALES_ALL_CLIENTS_LIST = BASE + 'clients/list/'
export const PREV_JOBS          = BASE + 'jobs/previous/sale/'
export const SALES_QUOTE_SITE_SEARCH = BASE + 'sales/quote/search/site/'
export const SALES_QUOTE_SITE_QUOTEID_SEARCH = BASE + 'sales/quote/search/id/'
export const SALES_JOB_SITE_SEARCH = BASE + 'jobs/search/site/'
export const SALES_JOB_SITE_JOBCODE_SEARCH = BASE + 'jobs/serach/job/id/'
export const PENDING_SCHD_JOBS = BASE + 'schedule/incomplete/view/'
export const REOCURING_SCHD_JOBS = BASE + 'jobs/'
export const REOCURING_JOB_FREQUENCY = BASE + 'sales/quote/frequency/edit/'
export const REOCURRING_SCHD_JOBS_FILTER = BASE + 'sales/jobs/recurring/ready/list/'
export const SALES_COMMON_QUOTE_REGISTER = BASE + 'sales/quote/'
export const SALES_WASTE_HOME_JOBS = BASE + 'sales/jobs/' //append page number
export const SALES_WASTE_JOBS = BASE + 'sales/jobs/waste/' //append page number
export const SALES_WASTE_SALE_PERFORMANCE = BASE + 'sales/performance/waste/' //append year & month
export const SALES_WASTE_TEMPLATES = BASE + 'pro-pdf/templates/'
export const SALES_WASTE_UNDER_FOLDERS = BASE + 'sales/folders/generate_quote/'
export const SALES_WASTE_ATTACH_FOLDERS = BASE + 'sales/folders/quote_attachments/waste/'
export const SALES_TEMP_CLIENTS = BASE + 'clients/temporary-client/list/'
export const SALES_CREATE_TEMP_CLIENT = BASE + 'clients/temporary-client/'
export const TEMP_CLIENT_SEARCH = BASE + 'clients/temporary-client/search/'
export const SALES_ATTACH_FOLDERS = BASE + 'sales/folders/quote_attachments/waste/'
export const SALES_GENERATE_TEMPLATE_DRAFT = BASE + 'sales/user/template/draft/'
export const SALES_GENERATE_SINGLE_TEMPLATE= BASE + 'sales/single/template/draft/'
export const SALES_CRUD_TEMPLATE = BASE + 'sales/quote/attach/templates/'
export const SALES_ALL_DRAFTS = BASE + 'sales/template/draft/all/'
export const SALES_FOLDER_MARKETING = BASE + 'sales/folder/list/waste/marketing/'
export const SALES_FOLDER_DESCRIPTION_WASTE = BASE + 'sales/folder/list/waste/description_of_waste/'
export const SALES_FOLDER_POWER_POINT = BASE + 'sales/folder/list/waste/power_point/'
export const SALES_FOLDER_PRICING = BASE + 'sales/folder/list/waste/pricing/' 
export const SALES_QUOTE_TENDER = BASE + 'sales/folder/list/waste/tender/'
export const SALES_QUOTE_FILTER = BASE + 'sales/filter/site-date/waste/'
export const SALES_QUOTE_FILTER_COMMON = BASE + 'sales/filter/site-date/'
export const SALES_QUOTE_GENERATE = BASE + 'sales/quote/'
export const SALES_QUOTE_GENERATE_ONLY = BASE + 'sales/quote/noemail/'
export const SALES_MULTI_QUOTE_GENERATE = BASE + 'sales/multiple_quotes/'
// export const SALES_MULTI_QUOTE_GENERATE_ONLY = BASE + 'sales/multiple_quote/noemail/'
export const SALES_QUOTE_ACTION  = BASE + 'sales/quote/manager/'
export const SALES_PERFORM_VIEW  = BASE + 'sales/manager/'
export const SALES_PERFORM_FILTER_VIEW  = BASE + 'sales/quote/all/filterby/status/'
export const SALES_WASTE_PERFORM_FILTER_VIEW  = BASE + 'sales/quote/waste/filterby/status/'
export const SALES_PUMP_PERFORM_FILTER_VIEW  = BASE + 'sales/quote/pumps/filterby/status/'
export const SALES_HILLS_PERFORM_FILTER_VIEW  = BASE + 'sales/quote/hills/filterby/status/'
export const SALES_DESTRUCTION_PERFORM_FILTER_VIEW  = BASE + 'sales/quote/destruction/filterby/status/'
export const SALES_COMMON_PERFORM_FILTER_VIEW  = BASE + 'sales/quote/TABTYPE/filterby/status/'
export const SALES_WASTE_PERFORM_VIEW  = BASE + 'sales/manager/waste/'
export const SALES_PUMP_PERFORM_VIEW  = BASE + 'sales/manager/pumps/'
export const SALES_HILLS_PERFORM_VIEW  = BASE + 'sales/manager/hills/'
export const SALES_DESTRUCTION_PERFORM_VIEW  = BASE + 'sales/manager/destruction/'
export const SALES_QUOTE_RESEND_MAIL = BASE + 'sales/quote/resent/mail/'  //append company / quote id
export const SALES_PUMP_JOBS = BASE + 'sales/jobs/pumps/' //append page number
export const SALER_PUMP_SALE_PERFORMANCE = BASE + 'sales/performance/pumps/' //append year & month
export const SALES_PUMP_QUOTE_GENERATE = BASE + 'sales/quote/pumps/'
export const SALES_PUMP_ATTACH_FOLDERS = BASE + 'sales/folders/quote_attachments/pumps/'
export const SALES_PUMP_FOLDER_MARKETING = BASE + 'sales/folder/list/pumps/marketing/'
export const SALES_PUMP_FOLDER_DESCRIPTION_WASTE = BASE + 'sales/folder/list/pumps/description_of_waste/'
export const SALES_PUMP_FOLDER_POWER_POINT = BASE + 'sales/folder/list/pumps/power_point/'
export const SALES_PUMP_FOLDER_PRICING = BASE + 'sales/folder/list/pumps/pricing/' 
export const SALES_PUMP_QUOTE_TENDER = BASE + 'sales/folder/list/pumps/tender/'
export const SALES_HILLS_JOBS = BASE + 'sales/jobs/hills/' //append page number
export const SALES_HILLS_SALE_PERFORMANCE = BASE + 'sales/performance/hills/' //append year & month
export const SALES_HILLS_QUOTE_GENERATE = BASE + 'sales/quote/hills/'
export const SALES_HILLS_ATTACH_FOLDERS = BASE + 'sales/folders/quote_attachments/hills/'

export const SALES_HILLS_FOLDER_MARKETING = BASE + 'sales/folder/list/hills/marketing/'
export const SALES_HILLS_FOLDER_DESCRIPTION_WASTE = BASE + 'sales/folder/list/hills/description_of_waste/'
export const SALES_HILLS_FOLDER_POWER_POINT = BASE + 'sales/folder/list/hills/power_point/'
export const SALES_HILLS_FOLDER_PRICING = BASE + 'sales/folder/list/hills/pricing/' 
export const SALES_HILLS_QUOTE_TENDER = BASE + 'sales/folder/list/hills/tender/'

export const SALES_DESTRUCTION_JOBS = BASE + 'sales/jobs/destruction/' //append page number
export const SALES_DESTRUCTION_SALE_PERFORMANCE = BASE + 'sales/performance/destruction/' //append year & month
export const SALES_DESTRUCTION_QUOTE_GENERATE = BASE + 'sales/quote/destruction/'
export const SALES_DESTRUCTION_ATTACH_FOLDERS = BASE + 'sales/folders/quote_attachments/destruction/'

export const SALES_DESTRUCTION_FOLDER_MARKETING = BASE + 'sales/folder/list/destruction/marketing/'
export const SALES_DESTRUCTION_FOLDER_DESCRIPTION_WASTE = BASE + 'sales/folder/list/destruction/description_of_waste/'
export const SALES_DESTRUCTION_FOLDER_POWER_POINT = BASE + 'sales/folder/list/destruction/power_point/'
export const SALES_DESTRUCTION_FOLDER_PRICING = BASE + 'sales/folder/list/destruction/pricing/' 
export const SALES_DESTRUCTION_QUOTE_TENDER = BASE + 'sales/folder/list/destruction/tender/'


export const SALES_JOB_ACTION = BASE + 'jobs/manager/status-change/'

export const SALES_JOB_ReSCHD = BASE + 'sales/jobs/ready/listing/'
export const SALES_JOB_ReSCHD_ALL = BASE + 'schedule/deleted/list/'

export const SALES_QUOTE_CLIENTS_SEARCH = BASE + 'sales/quote/client/search/'
export const SALES_JOB_MAIN_FILTER = BASE + 'jobs/filter/'
export const SALES_JOB_TYPEOSWASTE = BASE + 'sales/waste/type/'
export const QUOTE_GEN_ADD_TOW = BASE + 'clients/waste-type/add/'
export const EDIT_DEL_QUOTE_GEN_TOW = BASE + 'clients/waste-type/'
export const DEL_QUOTE_GEN_TOW_NEW = BASE + 'clients/edit/'
export const EDIT_QUOTE_GEN_TOW_NEW = BASE + 'clients/edit/site/update/'
export const SALES_PERFORMANCE_DETAIL_EXPORT_REPORT = BASE + 'sales/manager/' // TABTYPE/managerId/STATUS/export?month=0&&year=0
export const SALES_CUSTOM_JOB   =   BASE + 'jobs/custom/card/'
export const SALES_REVIEW = BASE + 'sales/quote/sales-team/'
export const SALES_QUOTE_HOSTORY = BASE + 'sales/Logging/details/view/' //append quote id
export const SALES_FILES_FOLDERS = BASE + 'sales/folder/list/' // section(tender,powerpoint etc...)/id/ 
export const SALES_TEMPLATE_REEDIT = BASE + 'sales/quote/attach/re_edited_templates/' // waste/101/

export const SCHEDULE_JOB_LIST_PRINT = BASE + 'sales/jobs/list-report/'
export const SCHEDULE_JOB_LIST_PRINT_NEW = BASE + 'sales/jobs/list-report/'

export const SALES_QUOTE_DELETE = BASE + 'sales/delete/quote/'

export const SALES_JOBCARD_DELETE_MULTIFILE = BASE + 'jobs/card/info/file/delete/'

export const SALES_QUOTE_DRAFT_GEN_CAUTION = BASE + 'sales/single/template/draft/'

export const SALES_QUOTE_COMMENT_URL = BASE + 'sales/comment/quote/'
export const SCHEDULE_JOB_LIST_COUNT_URL = BASE + 'jobs/listing/count/'

export const SCHEDULE_JOB_LIST_COUNT_URL_ALL = BASE + 'jobs/listing/count/'

export const SALES_REGISTER_LIST_EXPORT = BASE + 'sales/sales_register/export/'
export const SALES_QUOTE_REGISTER_EXPORT = BASE + 'sales/quote_register/export/'

//////////////////////////////////////////////// INTRANET /////////////////////////////////////////////////////////////

export const INTRANET_FOLDERS_FILES = BASE + 'intranet_archive/intra/upload/'
export const INTRANET_SHORTCUT_FOLDERS = BASE + 'intranet_archive/home/file-attachment/'
export const INTRANET_INITIAL_FOLDERS = BASE + 'drive/root/folder/'
export const INTRANET_FOLDERS = BASE + 'intranet_archive/folder/view/'

export const INTRANET_FOLDER_EDIT = BASE + 'drive/folder/rename/'
export const INTRANET_FOLDER_DELETE = BASE + 'drive/folder/delete/'
export const INTRANET_FILES_RENAME = BASE + 'drive/file/rename/'
export const INTRANET_FILES_FOLDER_GET = BASE + 'intranet_archive/folder/segment/' // id/
export const SALES_AND_INTRANET_FOLDER_CREATE = BASE + 'sales/folder/'  // <dynamic string>/create/   marketing description_of_waste power_point pricing tender intranet
export const FILE_EXPIRY_DATE = BASE + 'drive/file/expiry-date/'
export const FILES_FOLDERS_SEARCH       = BASE + 'drive/search/'
export const FILES_FOLDERS_MULTIPLE_DEL = BASE + 'drive/delete/'

//////////////////////////////////////////////// SCHEDULE /////////////////////////////////////////////////////////////////
 

export const SCHEDULE_JOBS                      = BASE + 'jobs/view/'

export const SCHEDULE_NORMAL_JOBS               = BASE + 'sales/ready/jobs/listing/'
export const SCHEDULE_REOCCURRING_JOBS          = BASE + 'sales/recurring/jobs/ready/listing/'
export const SCHEDULE_OUTSOURCE_JOBS            = BASE + 'sales/outsourced/jobs/ready/listing/'
export const SCHEDULE_NORMAL_JOBS_ALL           = BASE + 'schedule/list/'

export const SCHEDULE_AVAILABILITY              = BASE + 'schedule/availability/'
export const SCHEDULE_SCHEDULE_LIST             = BASE + 'schedule/view/'
export const SCHEDULE_SCHEDULE_MEM_LIST         = BASE + 'schedule/view/members/'
export const SCHEDULE_SCHEDULE_OUTSOURCED_LIST  = BASE + 'schedule/outsourced/jobschedule/view/'

export const SCHEDULE_ADD_JOB                   = BASE + 'schedule/jobschedule/add/'
export const SCHEDULE_COMPLETED_JOB             = BASE + 'schedule/completed/jobs/schedule/'
export const SCHEDULE_ADD_OUTJOB                = BASE + 'schedule/outsourced/jobschedule/'
export const SCHEDULE_DUMMY_ADD_JOB             = BASE + 'schedule/mockschedule/add/'          // mockjob schedule add
export const SCHEDULE_EDIT_JOB                  = BASE + 'schedule/jobschedule/edit/'
export const SCHEDULE_DELETE_JOB                = BASE + 'schedule/jobschedule/delete/'
export const SCHEDULE_ADD_TEAM                  = BASE + 'schedule/jobschedule/team/add/'
export const SCHEDULE_ADD_VEHICLE               = BASE + 'schedule/jobschedule/vehicle/add/'
export const SCHEDULE_ADD_IMAGE                 = BASE + 'schedule/jobschedule/images/add/'
export const SCHEDULE_IMAGE_MULTIPLE            = BASE + 'schedule/jobschedule/images/'
export const SCHEDULE_ADD_VIDEO                 = BASE + 'schedule/video/'
export const SCHEDULE_ADD_SIGNATURE_IMAGE       = BASE + 'schedule/jobschedule/signature/add/'

export const SCHEDULE_JOB_VIEW                  = BASE + 'schedule/jobschedule/view/'
export const SCHEDULE_COMMENT                   = BASE + 'schedule/jobschedule/comments/'
export const SCHEDULE_QUOTE_INFOS               = BASE + 'sales/client/quote/'
export const SCHEDULE_QUOTE_INFOS_NEW           = BASE + 'jobs/client/jobcard/'
export const SCHEDULE_MOCK_QUOTE_INFOS          = BASE + 'sales/mockup/'

export const SCHEDULE_DELETE_JOBLIST_JOB        = BASE + 'sales/jobs/ready/list/'

export const SCHEDULE_ADDITIONAL_VEHICLE        = BASE + 'schedule/additional/vehicles/'
export const SCHEDULE_RESCHEDULE_COMPLETED      = BASE + 'schedule/jobschedule/reschedule/'
export const SCHEDULE_EDIT_AFTERJOBCOMPLETE     = BASE + 'schedule/update/details/'

export const SCHEDULE_ADD_VIDEOS_IMAGES_TOGETHER    = BASE + 'schedule/jobschedule/web/images/add/'

// export const SCHEDULE_CAL_NEW_JOB_VIEW          = BASE_V3 + 'schedule/view/waste/' //?page=1&limit=10&from=2023-09-01&to=2023-09-30'

export const SCHEDULE_CAL_NEW_JOB_VIEW          = BASE_V4 + 'schedule/view/' //?page=1&limit=10&from=2023-09-01&to=2023-09-30'
export const SCHEDULE_CAL_NEW_OUT_JOB_VIEW      = BASE_V3 + 'schedule/outsourced/jobschedule/view/waste/' //?page=1&limit=10&from=2023-09-01&to=2023-09-09
export const SCHEDULE_JOB_COMMENT_VALIDATION    = BASE + 'schedule/jobschedule/comments/'
export const SCHEDULE_JOB_CARD_CERT_OF_DEST_VALIDATION = BASE + 'jobs/cardinfo/destruction/certificate/markasread/'

///////////////////////////////////////////////// OHS /////////////////////////////////////////////////////////////////////////

export const OHS_NEWS_LIST              = BASE + 'oh_and_s/news/view/'
export const OHS_NOTIFY_LIST            = BASE + 'oh_and_s/notification/view/'
export const OHS_NEWS_CREATE            = BASE + 'oh_and_s/news/create/'
export const OHS_NOTIFY_CREATE          = BASE + 'oh_and_s/notification/create/'
export const OHS_NEWS_DELETE            = BASE + 'oh_and_s/news/delete/'
export const OHS_NOTIFY_DELETE          = BASE + 'oh_and_s/notification/delete/'
export const OHS_NEWS_EDIT              = BASE + 'oh_and_s/news/edit/'
export const OHS_NEWS_READ              = BASE + 'oh_and_s/news/status/'
export const OHS_NOTIFICATION_READ      = BASE + 'oh_and_s/notification/status/'
export const OHS_NOTIFICATION_REPLY     = BASE + 'oh_and_s/notification/comment/'

export const OHS_AND_S_FOLDERS          = BASE + 'oh_and_s/folder/segment/list/'
export const OHS_AND_S_FOLDER_CREATE    = BASE + 'drive/folder/create/'
export const OHS_AND_S_FOLDER_DELETE    = BASE + 'drive/folder/delete/'
export const OHS_AND_S_FILE_ADD         = BASE + 'drive/file/create/'
export const OHS_AND_S_FILE_DELETE      = BASE + 'drive/file/delete/'

export const OHS_AND_S_SAFETYDATA       = BASE + 'oh_and_s/safety-data/'

///////////////////////////////////////////// ACCOUNTS ///////////////////////////////////////////////////////////////////////////

export const ACCOUNTS_FOLDER_CREATE     = BASE + 'accounts_files/folder/create/'
export const ACCOUNTS_FILES_FOLDER_GET  = BASE + 'accounts_files/folder/segment/list/'

export const ACCOUNTS_JOB_COMPLETED_LIST = BASE + 'jobs/completed/all/'
export const ACCOUNTS_JOB_TEMPLATE      = BASE + 'jobs/template/'   //append quote id or uuid
export const ACCOUNTS_JOB_STATUS_UPDATE = BASE + 'sales/paid-status/quote/'

export const INVOICE_COMPLETEDJOB_COMMENTS = BASE + 'jobs/comments/' // list/id , add/id, edit/id, delete/id
export const INVOICE_JOB_PAID_STATUS_CHANGE = BASE + 'sales/paid-status/v2/quote/' //append id

export const ACCOUNTS_TIMESHEET_LIST    = BASE + 'team/time-sheet/web/'
export const ACCOUNTS_TIMESHEET_STATUS_UPDATE = BASE + 'team/time-sheet/status/'
export const ACCOUNTS_TIMESHEET_LOG     = BASE + 'team/time-sheet/log/'
//////////////////////////////////////////////// MISCELLANEOUS ////////////////////////////////////////////////

export const NEWJOBCARD_ADD             = BASE + 'jobs/card/info/create/'
export const NEWJOBCARD_EDIT            = BASE + 'jobs/card/info/edit/'
export const NEWJOBCARD_VIEW            = BASE + 'jobs/card/info/view/' //append quote id
export const NEWJOBCARD_VIEW_WITH_JOB   = BASE + 'jobs/jobcard/job/view/' //append job_id
export const NEWJOBCARD_DELETE          = BASE + 'jobs/card/info/delete/'
export const NEWJOBCARD_RENAME          = BASE + 'jobs/card/rename/'
export const NEWJOBCARD_CLIENT_LIST     = BASE + 'jobs/card/info/get/'  //append tabtype/clientId to list client-wise filtered jobcards, add typeOfWaste as param to filter
export const NEWJOBCARD_ADD_FOLDER      = BASE + 'jobs/card/files/add/'
export const PREV_JOBCARD_DATA          = BASE + 'jobs/card/info/details/'

export const PRINT_ALL_LIST             = BASE + 'prints/clients/list/'

export const PUMPJOBCARDS               = BASE + 'jobs/card/pump/' //append id for edit and delete
export const PUMPJOBCARDVIEW            = BASE + 'jobs/card/pump/info/view/'

export const PUMPASSETRATES             = BASE + 'jobs/assets/list/'
export const CONVERTJOB                 = BASE + 'jobs/convert/oneoff/recurring/'

export const GET_DETAILS                = BASE + 'schedule/jobschedule/web/images/get-details/'
export const GENERATE_DETAILS           = BASE + 'schedule/jobschedule/web/images/generate-details/'
export const ADD_DETAILS                = BASE + 'schedule/jobschedule/web/images/add-details/'
